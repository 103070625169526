#booking {
  overflow-y: scroll; }

.modal {
  background: #162134;
  display: block;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%; }

.modal .modal-dialog, .modal-close {
  opacity: 0;
  visibility: hidden;
  height: 100%; }

.modal-body {
  height: calc(100vh - 50px);
  max-height: 100%; }
