.slider-control-centerleft {
  /* Previous */ }
  .slider-control-centerleft > button {
    visibility: hidden; }
    .slider-control-centerleft > button::after {
      position: absolute;
      padding: 10px;
      font-size: 2rem;
      right: -100%;
      visibility: visible !important;
      width: 2rem;
      height: 2rem;
      color: #fff;
      content: '\f053';
      font-family: 'Font Awesome 5 Pro';
      transform: translateY(-30%); }
      @media (max-width: 23.5em) {
        .slider-control-centerleft > button::after {
          right: -10%;
          font-size: 1rem;
          width: 1rem;
          height: 1rem; } }

.slider-control-centerright {
  /* Next */ }
  .slider-control-centerright > button {
    visibility: hidden; }
    .slider-control-centerright > button::after {
      position: absolute;
      padding: 10px;
      font-size: 2rem;
      left: -100%;
      visibility: visible !important;
      width: 2rem;
      height: 2rem;
      color: #fff;
      content: '\f054';
      font-family: 'Font Awesome 5 Pro';
      transform: translateY(-30%); }
      @media (max-width: 23.5em) {
        .slider-control-centerright > button::after {
          left: -10%;
          font-size: 1rem;
          width: 1rem;
          height: 1rem; } }

.slider-control-bottomcenter {
  display: none; }
